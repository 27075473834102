@import "~normalize.css/normalize";

/** Base **/
* {
  box-sizing: border-box;
  white-space: nowrap;
}

html {
  font-size: 1vmin;
}

body {
  width: 100vw;
  height: 100vh;
  font-family: "Titillium Web", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", "Helvetica", "Arial", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
  color: black;
  text-rendering: optimizeLegibility;
}

#root {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
}
