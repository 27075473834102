section.status {
  width: 100%;

  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 5vh;

  .logo {
    height: 10vh;
  }

  hr {
    width: 15vw;
  }

  .description {
    font-size: 5rem;
    line-height: 1.25;
    text-align: center;

    :last-child {
      font-weight: 200;
    }
  }
}
