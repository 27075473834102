header {
  width: 100vw;
  height: 12vh;

  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: white;

  > * {
    padding: 0 3.5rem;
  }

  > :first-child,
  > :last-child {
    flex: 1;
  }

  .logo {
    height: 55%;

    img {
      height: 100%;
    }
  }

  .title {
    font-size: 7rem;
    font-weight: bold;
  }
}
