@keyframes blinker {
  40% {
    opacity: 0;
  }
}

section.schedules {
  max-height: 82vh;
  font-size: 6.5rem;

  display: flex;
  flex-direction: column;
  overflow: hidden;

  > * {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .destination {
    flex-basis: 50%;
  }

  .time {
    flex-basis: 30%;
  }

  .occupancy {
    flex-basis: 20%;
  }

  .header {
    height: 12vh;
    padding: 2rem 3.5rem;
    background-color: #e51d2d;
    color: white;
    font-size: 4.5rem;

    > * {
      line-height: 1;

      :last-child {
        font-weight: 200;
      }
    }
  }

  .departure {
    height: 10vh;
    padding: 2rem 3rem;

    &:nth-child(odd) {
      background-color: #f8f8f8;
    }

    .destination {
      display: flex;
      align-items: center;
      font-weight: 600;

      img {
        height: 6rem;
        margin-right: 3rem;
      }
    }

    .time {
      .next {
        font-size: 4rem;
        line-height: 0.9;
        animation: blinker 1s cubic-bezier(0.7, -0.5, 0.3, 1.5) 0s infinite;

        :last-child {
          font-weight: 200;
        }
      }
    }

    .occupancy {
      display: flex;
      > * {
        margin: 0 0.4rem;
      }
    }
  }
}
